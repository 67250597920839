import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 32px;

  padding: 16px 24px;


  p {
    font-family: Gilroy Light;
    font-size: 16px;

    @media(min-width: 800px){
      font-size: 24px;
    }
  }

  p:nth-child(2) {
    font-size: 40px;
    margin: 15px 0;

    @media(min-width: 800px){
      font-size: 72px;
    }
  }

  p:nth-child(3) {
    font-size: 18px;
    @media(min-width: 800px){
      font-size: 24px;
    }
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 153px;
    height: 40px;

    margin-top: 37px;

    background-color: #AD99FF;

    border-radius: 8px;

    font-size: 16px;
    color: black;

    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;

  justify-content: space-between;

  margin: 0 auto;
`;