import React from 'react';
import ItemCard from './components/ItemCard';
import ScrollContainer from 'react-indiana-drag-scroll';

import { Container } from './styles';
import { projectsCards } from '../../../Content/Bio';

const Projects = () => {
  return (
    <Container>
      <h3>Projects</h3>
      <ScrollContainer className="scrollContainer">
        {projectsCards.map(({ title, description, thumbnail, link, dummy }) => (
          <ItemCard title={title} description={description} thumbnail={thumbnail} link={link} dummy={dummy} />
        ))}
      </ScrollContainer>
    </Container>
  );
};

export default Projects;
