export const getSize = (
  maxWidth: number,
  minWidth: number,
  initialNumber: number,
  finalNumber: number,
  isPixel = true,
) => {
  const viewWidth = window.screen.availWidth;

  if (viewWidth > maxWidth) return isPixel ? `${initialNumber}px` : initialNumber;
  if (viewWidth < minWidth) return isPixel ? `${finalNumber}px` : finalNumber;

  const diffRatio = (maxWidth - viewWidth) / (maxWidth - minWidth);
  const numberDiff = initialNumber - finalNumber;
  const result = initialNumber - ( numberDiff * diffRatio);

  return isPixel ? `${result}px` : result;
};
