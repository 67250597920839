import styled from 'styled-components';

export const Container = styled.footer`
  margin: 0 auto;
  margin-top: 48px;
  padding-bottom: 23px;

  text-align: center;
  width: fit-content;

  display: flex;
  align-items: center;

  @media(max-width: 1000px){//! CUSTOM
    flex-direction: column;
  }

  a:first-child {
    margin-right: 32px;
  }

  p {
    margin: 24px;

    font-family: Gilroy Light;
    font-size: 16px;
  }
`;
