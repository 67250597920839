import React from 'react';
import AcademicEducation from '../../Components/Bio/AcademicEducation';
import Experiences from '../../Components/Bio/Experiences';
import Footer from '../../Components/Footer';
import IamGabrielly from '../../Components/Bio/IamGabrielly';
import Projects from '../../Components/Bio/Projects';
import Tools from '../../Components/Bio/Tools';
import BlurCircles from '../../Components/BlurCircles';
import Toolbar from '../../Components/Toolbar';

import { Container } from './styles';

const Bio: React.FC = () => {
  return (
    <Container>
      <Toolbar />
      <IamGabrielly />
      <Projects />
      <Experiences />
      <Tools />
      <AcademicEducation />
      <Footer />

      <BlurCircles color="#AD99FF" top="30%" left="10%" />
      <BlurCircles color="#18DE97" top="40%" left="10%" />

      <BlurCircles color="#FFBA34" top="50%" left="90%" />
      <BlurCircles color="#FF6BC4" top="60%" left="90%" />

      <BlurCircles color="#FF6BC4" top="80%" left="10%" />

      <BlurCircles color="#558CDF" top="90%" left="90%" />
      <BlurCircles color="#AD99FF" top="90%" left="20%" />
      <BlurCircles color="#18DE97" top="90%" left="50%" />
    </Container>
  );
};

export default Bio;
