import styled from 'styled-components';

export const Container = styled.nav`
  display: flex;
  justify-content: space-between;

  padding: 16px 24px;
  max-width: 1200px;
  margin: 0 auto;

  h1 {
    visibility: hidden;
    font-size: 12px;
  }

  div {
    width: 32px;
    height: 32px;

    background-color: #AD99FF;
  }

  span {
    display: flex;
    align-items: center;
    gap: 24px;

  }

  img {
    cursor: pointer;
  }
`;
