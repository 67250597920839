import React from 'react';

import { Container } from './styles';

const Prototype = () => {
  return (
    <Container>
      <img src="assets/cursor-blue.png" alt='' className="cursor blue" />
      <img src="assets/cursor-yellow.png" alt='' className="cursor yellow" />
      <img src="assets/cursor-pink.png" alt='' className="cursor pink" />
      <img src="assets/cursor-orange.png" alt='' className="cursor orange" />
      <img src="assets/cursor-green.png" alt='' className="cursor green" />
      <img src="assets/wireframe.png" alt="" className="wireframe prototype" />
      <img src="assets/high-fidality.png" alt="" className="high prototype" />
    </Container>
  );
};

export default Prototype;
