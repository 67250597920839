import styled from 'styled-components';

export const Container = styled.section`
  margin: 0 auto;
  margin-top: 60px;

  max-width: 1200px;

  @media(max-width: 1200px){
    margin-top: 72px;
  }

  h3 {
    font-family: Gilroy Light;
    font-size: 24px;
    margin: 24px 0;
    margin-left: 24px;
  }

  .scrollContainer {
    display: flex;
    gap: 16px;

    height: 150px;

    span {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    span:first-child {
      margin-left: 24px;
    }

    span:last-child {
      margin-right: 24px;
    }
  }
`;
