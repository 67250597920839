import styled from 'styled-components';

export const Container = styled.section`
  margin: 0 auto;
  margin-top: 56px;
  max-width: 1200px;

  h3 {
    font-family: Gilroy Light;
    font-size: 24px;
    margin-bottom: 24px;
    padding: 0 24px;
  }

  .scrollContainer {
    padding: 30px 0;
    margin: -30px 0;
    display: flex;

    @media(min-width: 1190px){//! CUSTOM
      flex-wrap: wrap;
      height: 600px;
    }

    @media(max-width: 1190px){
      & > :first-child {
          
        margin-left: 24px;
      }
      
      & > :last-child {
        margin-right: 24px;
      }
    }

  }
`;
