import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

import { Container } from './styles';

const Tools = () => {
  return (
    <Container>
      <h3>Tools</h3>
      <ScrollContainer className="scrollContainer">
        <img src="assets/figma.png" alt="figma app" />
        <img src="assets/xd.png" alt="xd app" />
        <img src="assets/invision.png" alt="invision app" />
        <img src="assets/axure.png" alt="axure app" />
        <img src="assets/use-berry.png" alt="use-berry app" />
        <img src="assets/analytics.png" alt="analytics app" />
        <img src="assets/illustrator.png" alt="illustrator app" />
        <img src="assets/photoshop.png" alt="photoshop app" />
        <img src="assets/visual-eyes.png" alt="visual-eyes app" />
        <img src="assets/notion.png" alt="notion app" />
        <img src="assets/miro.png" alt="miro app" />
        <img src="assets/pro-create.png" alt="procreate app" />
        <img src="assets/premiere.png" alt="premiere app" />
      </ScrollContainer>
    </Container>
  );
};

export default Tools;
