import styled, { css } from 'styled-components';
import { getSize } from '../../../../../Utils/getSize';
interface IItemCard {
  dummy?: boolean;
}

export const Container = styled.article<IItemCard>`
  max-height: 208px;
  min-width: ${getSize(1200, 360, 272, 190)};

  border-radius: 8px;
  box-shadow: 0px 10px 20px 0px #0000001A;

  margin-left: 16px;

  display: flex;
  flex-direction: column;

  overflow: hidden;

  a {
    height: 100%;
    pointer-events: ${({ dummy }) => dummy && 'none' };
    cursor: ${({ dummy }) => dummy && 'default' };
  }

  img {
    height: calc(100% - 64px);
    object-fit: cover;

    @media(max-width: 1200px){
      width: 100%;
    }
  }
`;

const dummyCssDescription = css`
  display: flex;
  justify-content: center;
  align-items: center;
  
  height: 100%;
  padding: 32px;
`;

const dummyCssParagraph = css`
  font-family: Gilroy Light;
  font-size: 12px;
  text-align: center;
  color: black;
  line-height: 14px;
`;

export const Description = styled.div<IItemCard>`
  background-color: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 64px;
  padding: 5px 16px;

  background-blend-mode: luminosity;
  backdrop-filter: blur(80px);

  ${({ dummy }) => dummy && dummyCssDescription};

  h2 {
    font-family: SF Pro Text Semibold;
    font-size: 12px;
  }
  p {
    font-family: SF Pro Text Medium;
    font-size: 12px;
    color: #797979;
    line-height: 16px;

    ${({ dummy }) => dummy && dummyCssParagraph};
  }
`;