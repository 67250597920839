import React from 'react';

import { Container, Description, WindowTile, Wrapper } from './styles';

export interface INotification {
  role: string;
  company: string;
  thumbnail: string;
  volunteer: boolean;
}

const Notification = ({ role, company, thumbnail, position, volunteer }: INotification & { position: number }) => {
  return (
    <Wrapper position={position}>
      <Container>
        <WindowTile volunteer={volunteer}>
          <div/>
          <div/>
          <div/>
          <p>Volunteer Work</p>
        </WindowTile>
        <Description>
          <img src={thumbnail} alt={company} />
          <span>
            <p>{role}</p>
            <p>{company}</p>
          </span>
        </Description>
      </Container>
    </Wrapper>
  );
};

export default Notification;
