import React from 'react';

import { Container, Description } from './styles';

export interface IItemCard {
  title: string;
  description: string;
  thumbnail: string;
  link: string;
  dummy?: boolean;
}

const ItemCard = ({ title, description, thumbnail, link, dummy }: IItemCard) => {
  return (
    <Container dummy={dummy}>
      <a href={link} rel="noreferrer" target="_blank" >
        {!dummy && <img src={thumbnail} alt={title} />}
        <Description dummy={dummy}>
          {!dummy && <h2>{title}</h2>}
          <p>{description}</p>
        </Description>
      </a>
    </Container>
  );
};

export default ItemCard;
