import React from 'react';
import { notificationCards } from '../../../Content/Bio';
import Notification from './components/Notification';

import { Container, Wrapper } from './styles';

const Experiences = () => {
  return (
    <Container>
      <h3>Experiences</h3>
      <Wrapper>
        {notificationCards.map(({ role, company, thumbnail, volunteer }, index) => (
          <Notification key={index} role={role} company={company} thumbnail={thumbnail} position={index % 2} volunteer={volunteer} />
        ))}
      </Wrapper>
      <span>More experiences in my LinkedIn</span>
    </Container>
  );
};

export default Experiences;
