import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  *, h1, h2 {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    font-family: Montserrat, sans-serif;
  }

  * {
    &::selection {
      background-color: #CC66EE55;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }
  
  *, button, input {
    border: 0;
    background: none;
    outline: 0;
    font-family: Montserrat;
  }

  html, body, #root {
    max-height: 100vh;
    max-width: 100vw;

    width: 100%;
    height: 100%;
  }

  ul {
    list-style: none;
  }

  :root {
    @font-face {
      font-family: "Gilroy Extra Bold";
      src: url('/fonts/Gilroy/Gilroy-ExtraBold.otf');
    }

    @font-face {
      font-family: "Gilroy Light";
      src: url('fonts/Gilroy/Gilroy-Light.otf');
    }

    @font-face {
      font-family: "SF Pro Text Medium";
      src: url('fonts/SFPro/SF-Pro-Text-Medium.otf');
    }

    @font-face {
      font-family: "SF Pro Text Regular";
      src: url('fonts/SFPro/SF-Pro-Text-Regular.otf');
    }

    @font-face {
      font-family: "SF Pro Text Semibold";
      src: url('fonts/SFPro/SF-Pro-Text-Semibold.otf');
    }

    --purple: #9019C2;
    --bgGray: #1C1C1C;
    --textGray: #C1C1C1;

    --cyan: #00FFF0 ;
    --pink: #FF00E5;
  }



`