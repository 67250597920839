import styled from 'styled-components'
import { getSize } from '../../../../../Utils/getSize';

interface IWrapper {
  position: number
}

interface IWindowTile {
  volunteer: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  display: flex;
  justify-content: ${({position}) => position ? 'flex-end' : 'flex-start'};

  @media(max-width: 1076px){
    width: 100%;
  }
`;

export const Container = styled.div`
  width: ${getSize(880, 640, 400, 312)};
  height: 121px;

  background-color: #F4F4F4;
  
  border-radius: 10px;

  overflow: hidden;

  margin-bottom: 24px;

  display: flex;
  flex-direction: column;

  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2);

  float: left;
`;

export const WindowTile = styled.div<IWindowTile>`
  background-color: ${({ volunteer }) => volunteer ? '#61A0FF33' : 'white' };
  
  width: 100%;
  height: 28px;
  
  border-bottom: 1px solid #0000000D;
  
  display: flex;
  align-items: center;
  gap: 8px;
  
  padding-left: 8px;

  p {
    font-size: 13px;
    font-family: SF Pro Text Regular;
    color: #798699;
    
    margin-left: 10px;
    margin-top: 2px;

    display: ${({ volunteer }) => volunteer ? 'initial' : 'none'};
  }

  div {
    border: 1px solid #0000000D;
    border-radius: 50%;
    
    background-color: #f2f2f2;
    
    width: 12px;
    height: 12px;
  }
  
  div:nth-child(1) {
    background-color: #F96057;
  }
  
  div:nth-child(3) {
    background-color: #5FCF65;
  }
`;

export const Description = styled.div`
  height: 100%;

  display: flex;
  align-items: center;

  margin-left: 20px;
  
  span {
    margin-left: 10px;
    font-size: 13px;
    
    p:nth-child(1) {
      font-family: SF Pro Text Medium;
    }
    
    p:nth-child(2) {
      font-family: SF Pro Text Regular;
      color: #797979;
      margin-top: 10px;
    }
  }
`;