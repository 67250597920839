import styled from 'styled-components';

interface IContainer {
  top?: string;
  left?: string;
  color: string;
}

export const Container = styled.div<IContainer>`
  width: 200px;
  height: 200px;
  position: absolute;
  border-radius: 50%;
  filter: blur(80px) opacity(0.3);
  transform: translate(-50%, -50%);
  z-index: -10;
  
  background-color: ${({ color }) => color};
  top: ${({ top }) => top};
  left: ${({ left }) => left};

  @media(min-width: 1200px){
    width: 500px;
    height: 500px;
    opacity: 0.5;
  }
`;
