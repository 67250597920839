import styled from 'styled-components';

export const Container = styled.section`
  margin: 0 auto;
  margin-top: 64px;

  padding: 0 24px;

  max-width: 1200px;

  @media(min-width: 1200px){
    margin-top: 72px;
  }

  h3 {
    font-family: Gilroy Light;
    font-size: 24px;
    margin-bottom: 24px;
  }

  & > span {
    font-size: 12px;
    font-family: Gilroy Light;
    
    @media(min-width: 1200px){
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-left: -270px;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media(min-width: 1076px){
    gap: 24px;

    & > :nth-child(2) {
      margin-left: 200px;
    }
    & > :nth-child(3) {
      margin-left: 80px;
    }
    & > :nth-child(4) {
      margin-left: 200px;
    }
  }

`;