import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

const Toolbar = () => {
  return (
    <Container>
      <Link to="/"><img src="assets/icone.svg" alt="ícone GV"/></Link>
      <h1>Gabrielly Vicente</h1>
      <span>
        <a target="_blank" rel="noreferrer" href="https://br.linkedin.com/in/gabriellyvicente"><img src="assets/linkedin.svg" alt="linkedin" /></a>
        <a target="_blank" rel="noreferrer" href="mailto:oi@gabriellyvicente.com">Contact me</a>
      </span>
    </Container>
  );
};

export default Toolbar;
