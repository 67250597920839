import React from 'react';

import { Container } from './styles';

const Footer = () => {
  return (
    <Container>
      <span>
        <a target="_blank" href="https://br.linkedin.com/in/gabriellyvicente" rel='noreferrer'><img src="assets/linkedin.svg" alt="linkedin" /></a>
        <a target="_blank" href="mailto:oi@gabriellyvicente.com" rel='noreferrer'><img src="assets/email.svg" alt="email" /></a>
      </span>
      <p>All rights reserved Gabrielly Vicente© - 2022</p>
    </Container>
  );
};

export default Footer;
