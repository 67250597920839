import { ICard } from "../../Components/Bio/AcademicEducation/Card";
import { INotification } from "../../Components/Bio/Experiences/components/Notification";
import { IItemCard } from "../../Components/Bio/Projects/components/ItemCard";

export const projectsCards: IItemCard[] = [
  {
    title: 'App Coração Alerta',
    description: 'UX Design, Research and User Interface',
    thumbnail: 'assets/coracao-alerta.png',
    link: 'https://www.behance.net/gallery/99020751/UX-e-UI-Aplicativo-Coracao-alerta-Pesquisa-e-interface',
  },
  {
    title: 'App Toronto Cupcake',
    description: 'UX Design, Research and User Interface',
    thumbnail: 'assets/toronto-cupcake.png',
    link: 'https://xd.adobe.com/view/179f07b4-9811-4565-a703-054d3431c5ac-42cc/',
  },
  {
    title: 'Site MarqMaq',
    description: 'UX Design, Research and User Interface',
    thumbnail: 'assets/marqmaq-sistema.png',
    link: 'https://xd.adobe.com/view/d499f9ba-7cf7-48b5-9721-8b5a908a8ff8-81ca/',
  },
  {
    title: 'Site Mobile MarqMaq',
    description: 'UX Design, Research and User Interface',
    thumbnail: 'assets/marqmaq-mobile.png',
    link: 'https://xd.adobe.com/view/0c5ed613-1037-4cd3-97c5-bea3525a86b9-590d/',
  },
  {
    title: 'App AlimentaCão',
    description: 'UX Design, Research and User Interface',
    thumbnail: 'assets/alimentacao.png',
    link: 'https://xd.adobe.com/view/ba995e8e-19c8-4207-8da5-9e44d2bf27f5-efca/screen/df52f6fb-736a-45ba-9b91-49d0fc68e73b?fullscreen&hints=off',
  },
  {
    title: 'Bike Away',
    description: 'UX Design, Research and User Interface',
    thumbnail: 'assets/bike-away.png',
    link: 'https://www.figma.com/proto/Ll8fihbNwx6mVgZBTldZbM/Bikeaway-GabriellyVicente?page-id=0%3A1&node-id=19%3A277&viewport=241%2C48%2C0.5&scaling=scale-down&starting-point-node-id=19%3A277',
  },
  {
    title: 'MarqMaq System',
    description: 'UX Design, Research and User Interface',
    thumbnail: 'assets/marqmaq-system.png',
    link: 'https://xd.adobe.com/view/9827a66a-018c-4b9d-af66-90006461ee8f-cd74/',
  },
  {
    title: '',
    description: 'To see more details and works, contact me.',
    thumbnail: '',
    link: '/',
    dummy: true,
  },
];

export const notificationCards: INotification[] = [
  {
    role: 'Senior Product Designer',
    company: 'Minu',
    thumbnail: 'assets/minu-logo.png',
    volunteer: false,
  },
  {
    role: 'Product Designer',
    company: 'Cadmus',
    thumbnail: 'assets/cadmus-logo.png',
    volunteer: false,
  },
  {
    role: 'Product Designer',
    company: 'VICCO',
    thumbnail: 'assets/vicco-logo.png',
    volunteer: false,
  },
  {
    role: 'Local leader',
    company: 'IXDA',
    thumbnail: 'assets/ixda-logo.png',
    volunteer: true,
  },
];

export const academicCards: ICard[][] = [
  [
    {
      course: 'Digital Design, Bachelor',
      institute: 'University of Araraquara',
      thumbnail: 'assets/uniara-logo.png',
    },
    {
      course: 'UX Design',
      institute: 'EBAC',
      thumbnail: 'assets/ebac-logo.png',
    },
  ],
  [
    {
      course: 'Interaction Design',
      institute: 'UC San Diego',
      thumbnail: 'assets/uc-san-diego-logo.png',
    },
    {
      course: 'UX Design, Professional Certificate',
      institute: 'Google',
      thumbnail: 'assets/google-logo.png',
    },
  ],
  [
    {
      course: 'UX Metrics',
      institute: 'PunkMetrics',
      thumbnail: 'assets/punk-metrics-logo.png',
    },
    {
      course: 'UX Writing',
      institute: 'Aldeia',
      thumbnail: 'assets/aldeia-logo.png',
    },
  ],
  [
    {
      course: 'Design Strategy',
      institute: 'EBAC',
      thumbnail: 'assets/ebac-logo.png',
    },
    {
      course: 'Design Thinking',
      institute: 'Saint Paul',
      thumbnail: 'assets/saint-paul-logo.png',
    },
  ],
  [
    {
      course: 'Human Centred Design',
      institute: 'UC San Diego',
      thumbnail: 'assets/uc-san-diego-logo.png',
    },
    {
      course: 'Visual Design',
      institute: 'EBAC',
      thumbnail: 'assets/ebac-logo.png',
    },
  ],
];