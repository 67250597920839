import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { academicCards } from '../../../Content/Bio';
import Card from './Card';
import { Container } from './styles';

const AcademicEducation = () => {
  return (
    <Container>
      <h3>Academic Education</h3>
      <ScrollContainer className="scrollContainer">
        {
          academicCards.map((cards) => (
            <span>
              {
                cards.map(({ course, institute, thumbnail }) => (
                  <Card course={course} institute={institute} thumbnail={thumbnail} />
                ))
              }
            </span>
          ))
        }
      </ScrollContainer>
    </Container>
  );
};

export default AcademicEducation;
