import React from 'react';

import { Container, Description } from './styles';

export interface ICard {
  course: string;
  institute: string;
  thumbnail: string;
}

const Card = ({ course, institute, thumbnail }: ICard) => {
  return (
    <Container>
      <Description>
        <p>{course}</p>
        <p>{institute}</p>
      </Description>
      <img src={thumbnail} alt={institute} />
    </Container>
  );
};

export default Card;
