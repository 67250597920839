import React from 'react';

import { Container } from './styles';

interface IBlurCircles {
  top?: string;
  left?: string;
  color: string;
}

const BlurCircles = ({
  color,
  left,
  top,
}: IBlurCircles) => (
  <Container
    color={color}
    left={left}
    top={top}
  />
);

export default BlurCircles;
