import styled from 'styled-components';

export const Container = styled.article`
  min-width: 224px;
  height: 64px;

  padding: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.07);

  background-color: rgba(255, 255, 255, 0.3);
  background-blend-mode: luminosity;
  backdrop-filter: blur(50px);

  img {
    border-radius: 6px;

    width: 48px;
    height: 48px;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  height: 100%;

  p {
    font-family: SF Pro Text Medium;
    font-size: 11px;
  }
`;