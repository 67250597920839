import React from 'react';
import Prototype from './components/Prototype';

import { Container, Wrapper } from './styles';

const IamGabrielly = () => {
  return (
    <Wrapper>
      <Container>
        <p>Hi, I'm</p>
        <p>Gabrielly</p>
        <p>Senior Product Designer</p>
        <a href='mailto:oi@gabriellyvicente.com' target="_blank" rel="noreferrer">Contact me</a>
      </Container>
      <Prototype />
    </Wrapper>
  );
};

export default IamGabrielly;
