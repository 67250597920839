import styled from 'styled-components';

export const Container = styled.div`
  height: 400px;
  width: 100%;

  @media(min-width: 700px){
    width: 400px;
  }

  position: relative;

  @keyframes rize {
    from {
      opacity: 0;
      transform: scale(0.98) translate(-50%, -12px);
    }
    to {
      opacity: 1;
      transform: scale(1) translate(-50%, -12px);
    }
  }

  @keyframes vanish {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .prototype {
    position: absolute;
    left: 50%;
  }

  .cursor {
    position: absolute;
    top: 0;
    left: 0;
  }

  .wireframe {
    transform: translateX(-39%);
    animation: vanish 800ms ease-in-out forwards;
    animation-delay: 1.5s;
  }

  .high {
    transform: translate(-50%, -12px);
    opacity: 0;
    animation: rize 800ms ease-in-out forwards;
    animation-delay: 1.5s;
  }

  .cursor {
    position: absolute;
    z-index: 10;
  }

  @keyframes blue {
    to { top: 10%; left: 24%;}
  }

  .blue {
    animation: blue 800ms ease-in-out forwards;
    top: 0;
    left: -15%;
    animation-delay: 1.5s;
  }

  @keyframes yellow {
    to { top: 22%; left: 64%;}
  }
  .yellow {
    animation: yellow 800ms ease-in-out forwards;
    top: 0;
    left: 85%;
    animation-delay: 1.5s;
  }

  @keyframes pink {
    to { top: 45%; left: 15%;}
  }
  .pink {
    animation: pink 800ms ease-in-out forwards;
    top: 55%;
    left: -15%;
    animation-delay: 1.5s;
  }

  @keyframes orange {
    to { top: 83%; left: 20%;}
  }
  .orange {
    animation: orange 800ms ease-in-out forwards;
    top: 95%;
    left: -15%;
    animation-delay: 1.5s;
  }

  @keyframes green {
    to { top: 58%; left: 68%;}
  }
  .green {
    animation: green 800ms ease-in-out forwards;
    top: 82%;
    left: 92%;
    animation-delay: 1.5s;
  }

`;
