import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  margin-top: 48px;
  
  max-width: 1200px;

  @media(min-width: 1200px){
    margin-top: 72px;
  }

  h3 {
    font-family: Gilroy Light;
    font-size: 24px;
    margin-bottom: 24px;
    margin-left: 24px;
  }

  .scrollContainer {
    margin: 0 auto;

    display: flex;
    gap: 16px;

    img:first-child {
      margin-left: 24px;
    }

    img:last-child {
      margin-right: 24px;
    }

    @media(min-width: 860px){//! CUSTOM
      width: fit-content;
      padding: 12px;
      background-color: rgba(255, 255, 255, 0.4);
      background-blend-mode: luminosity;
      backdrop-filter: blur(80px);
      border-radius: 20px;
      border: 1px solid #F7F7F7;
    }
  }
`;
