import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom'
import Bio from '../Pages/Bio';

const Routes = () => (
  <BrowserRouter>
    <Route path="/" component={Bio}/>
  </BrowserRouter>
);

export default Routes;
